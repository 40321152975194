.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #001f6b;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 30px;
	color: white;
}

.card {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	/* this adds the "card" effect */
	padding: 16px;
	text-align: center;
	background-color: grey;
	height: calc(100% - 32px);
}

/* On mouse-over, add a deeper shadow */
.card:hover {
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.column {
	float: left;
	width: 50%;
	padding-right: 20px;
}

/* Remove extra left and right margins, due to padding in columns */
.row {
	display: flex;
	justify-content: center;
	margin: 0 -5px;
}

/* Clear floats after the columns */
.row:after {
	content: "";
	display: flex;
	clear: both;
}

/* Add some padding inside the card container */
.container {
	padding: 2px 16px;
	display: inline-block;
}


.App-link {
	color: #61dafb;
}

.overTen {
	background-color: red;
}

.card-wrap {
	width: 400px;
	position: relative;
	display: inline-block;
	margin: 10px;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: rgba(0, 0, 0, 0.5) 0px 7px 29px 0px;
	height: 300px;
}

h3 {
	font-weight: bold;
}

h4 {
	font-weight: normal;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}